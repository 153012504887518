.modal-body {
  /* height: 700px !important; */
  margin-left: "20px";
  margin-right: "20px";
  /* width: 100% !important; */
}
.css-1esb739-MuiPaper-root-MuiCard-root {
  overflow: none !important;
}

.css-16f6rnb {
  /* width: 98% !important; */
  /* margin-left: 10px !important; */
  /* margin-right: 10px !important; */
}
.css-981lsi {
  width: 98% !important;
}

.ql-editor {
  min-height: 300px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

/* .text-box {
  min-height: 400px;
} */
