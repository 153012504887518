/* last updated by: sonali
date: 11/01/2024
purpose: css updated for week selection date picker */
.rs-calendar-table-cell-week-number {
    color: #2952a3;
    /* color: white; */
    padding: "10px";
    font-weight: bold;
}

.rs-calendar-table-row:not(.rs-calendar-table-header-row):hover .rs-calendar-table-cell-content{
    background-color: rgba(204, 233, 255, .5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
}

.rs-calendar-table-row:not(.rs-calendar-table-header-row):has(.rs-calendar-table-cell-selected) .rs-calendar-table-cell-content {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
}

.rs-calendar-table-row-selected .rs-calendar-table-cell-content{
        background-color: #3498ff;
            background-color: var(--rs-bg-active);
            -webkit-box-shadow: none;
            box-shadow: none;
            color: #fff;
            color: var(--rs-calendar-date-selected-text);
}