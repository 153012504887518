.css-12blrsj-MuiListItem-root {
  /* background-color: aquamarine; */
  /* color: black !important; */
}

/* sidnav fade when modal is shown */
.css-lq19x5-MuiDrawer-docked .MuiDrawer-paper {
  z-index: 1;
}
.css-ef811l-MuiListItemIcon-root {
  background: #ffffff;
}

@media (min-width: 1200px) {
  .css-ef811l-MuiListItemIcon-root {
    background: #ffffff;
  }
}

@media (min-width: 1200px) {
  .css-rufnm2-MuiListItemIcon-root {
    background: #ffffff;
  }
}

/* .css-ef811l-MuiListItemIcon-root{
    background: none;
} */

.css-lemao1-MuiListItemText-root {
  color: black !important;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.activeBar {
  background-color: rgba(59, 80, 146, 1);
}
.activeBarText {
  color: white !important;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.is-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.css-e1df1o {
  z-index: 2 !important;
}

.css-1qe7k5w-MuiIcon-root {
  display: none !important;
}
