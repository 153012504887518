.excellent {
  width: 113px;
  height: 25px;
  background-color: "#B7F4BB";
}
.medium {
  width: 113px;
  height: 25px;
  background-color: "#EA7500";
}
.poor {
  width: 113px;
  height: 25px;
  background-color: "#FFD2D2";
}

/* custom CSS for softselect multiple option*/
.css-1kdtujw-multiValue div:first-of-type {
  color: #000 !important;
}
.css-1kdtujw-multiValue {
  background-color: #e9e9e9 !important;
  color: #000 !important;
}
/* custom CSS for softselect multiple option*/

/* .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 90px;

  margin-right: auto;
  margin-left: auto;
  margin-bottom: 42px;
  border-radius: 50px;
  height: 30px;
} */

/* .css-16f6rnb {
  width: 50%;
} */

/* css for shortlist page table z index  */
.css-ojuu5g-control {
  z-index: 1 !important;
}
.css-1n102nq {
  z-index: 0 !important;
}
/* css for shortlist page table z index  */

.css-l4kyc0-Control {
  /* border: none !important; */
  /* height: ; */
}
.jobrole_dd {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 200px;
  /* padding: 5px 10px; */
  /* margin: 0 auto 42px auto; */
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 26px;
  border-radius: 5px;
  /* height: 30px; */
  border: none !important;
}
.jobrole_dd_list {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 200px;
  /* padding: 5px 10px; */
  /* margin: 0 auto 42px auto; */
  margin-right: auto;
  margin-left: auto;
  /* margin-bottom: 26px; */
  border-radius: 5px;
  /* height: 30px; */
  border: none !important;
}

.apply-modal {
  justify-content: center !important;
}

.grid-class {
  width: 265px !important;
}
.wrap-class {
  width: 297px !important;
}

.css-y9gdep-MuiInputBase-root {
  border-radius: 4px !important;
}

/* .css-y9gdep-MuiInputBase-root {
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  cursor: default !important;
  /* display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important; *
  display: flex !important;
  -webkit-box-flex-wrap: wrap !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  min-height: 38px !important;
  outline: 0 !important ;
  position: relative !important;
  -webkit-transition: all 100ms !important;
  transition: all 100ms !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-color: rgb(204, 204, 204);
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
} */
