.batch-table .ant-table-thead > tr > th {
  /* .ant-table .ant-table-thead .ant-table-cell */
  background-color: #fff0e7;
  /* background-color: red; */
}

.print {
  background-image: url("../../assets/images/jobfairsvgs/QRbg1.png");
  height: 750px;
  width: 450px;
  color: "red";
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  object-fit: cover;
  /* justify-content: center; */
  margin: auto;
  /* padding: 10px; */
}

.skill-bg-img {
  background-image: url("../../assets/images/jobfairsvgs/skillbgimg.svg");
  height: 200px;
  width: 100%;
  background-color: #157dec;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.recharts-label-list .recharts-text {
  fill: white !important;
  font-size: small !important;
}
