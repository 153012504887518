.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 300px;
  overflow-y: scroll;
}

.toolbar-class {
  border: 1px solid #ccc;
}
