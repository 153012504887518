/* *** Overview Card css  */
@media (max-width: 1200px) {
  .css-1uxice8 {
    width: "200px" !important;
  }
}
@media (min-width: 1200px) {
  .css-ef811l-MuiListItemIcon-root {
    /* background: #17c1e8; */
    background: #fff !important;
  }
  .css-rufnm2-MuiListItemIcon-root {
    background: #ffffff !important;
  }
  .css-pys03g {
    background: #fff !important;
  }
}
/* *** Overview Card css  */

/* ** header css ** */
/* .css-1rujksk-MuiContainer-root {
  margin-left: 25px !important ;
  margin-right: 25px !important;
} */
/* ** header css ** */

.css-1e83rmc-menu {
  z-index: 9999;
}

.submit-btn-box {
  width: "225px";
  height: "45px";
  background-color: "#3B5092";
  padding: "13px 84px";
  border-radius: "5px";
}

.submit-btn-font {
  color: "#FFFFFF";
  font-size: "15px";
  font-weight: "medium";
  line-height: "19px";
}

.cancel-btn-box {
  width: "225px";
  height: "45px";
  background-color: "#FFFFFF";
  padding: "13px 84px";
  border-radius: "5px";
}

.cancel-btn-font {
  color: "#3B5092";
  font-size: "15px";
  font-weight: "medium";
  line-height: "19px";
}

.btn-edit {
  text-indent: -999px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  /* padding: 0;
  margin: 0; */
  /* border: 1px solid transparent; */
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.wrapper {
  position: relative;
}

.swal2-deny {
  font-size: 0.75rem !important;
  border-radius: 0.5rem !important;
  padding: 0.75rem 1.5rem !important;
  height: 2.5rem !important;
  text-transform: uppercase !important;
  background-color: #992fbd !important;
}

/* div {
  display: flex;
  
} */
.ant-input-affix-wrapper {
  width: 40% !important;
}

.css-1pi8xg4-MuiGrid2-root {
  justify-content: space-between !important;
  z-index: 0 !important;
}

/* application stage */
.default {
  width: 8px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.completed-class {
  width: 8px;
  height: 8px;
  background-color: #3b5092;
  border-radius: 50%;
}

.in_progress-class {
  width: 8px;
  height: 8px;
  background-color: #ffae5e;
  border-radius: 50%;
}

.rejected-class {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
}

/* applicant stage */

/* table css for zindex */
.css-bty0wn,
.css-1p2g72m,
.css-194ifow {
  z-index: 0 !important;
}

/* css for instruction modal */
.modal-dialog,
.modal-xl,
.modal-dialog-centered {
  margin-top: "78px";
}

.modal-content {
  margin-top: 50px !important;
}

/* sidenav zindex css */
.css-lq19x5-MuiDrawer-docked .MuiDrawer-paper {
  /* z-index: 2; */
  z-index: 1;
  margin-top: 80px;
  overflow-y: auto;
  height: 80vh;
}

.css-1jmpj1x {
  background: #3b5092 !important;
  color: #fff !important;
}
/* .css-1clhifh {
  background: #17c1e8 !important;
} */
.css-lq19x5-MuiDrawer-docked {
  background: #3b5092 !important;
  color: #fff !important;
}
.css-n4phvm-MuiList-root {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* sidenav zindex css */

/*Export csv modal css*/
.HeaderCSV2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  margin-left: 7px;
  text-align: center;
}
.HeaderCSV {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  margin-left: 7px;
}

.CloseBtn {
  margin-right: 7px;
  margin-top: -16px;
  padding: 0px;
  background-color: #ffffff;
  cursor: "pointer";
  border-radius: 4px;
  color: grey;
}

.CloseBtn:hover {
  background-color: #ecf0f1;
  cursor: "pointer";
  border-radius: 4px;
  margin-right: 7px;
  margin-top: -16px;
  padding: 0px;
  color: rgba(0, 0, 0, 0.88);
}

.UlCSV {
  margin: 0px;
  margin-left: -25px;
  margin-top: -15px;
  padding-bottom: 6px;
}

.ListCSV {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
}

.input-box {
  height: 35px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  /* color: rgba(0, 0, 0, 0.87) */
  box-sizing: border-box;
  position: relative;
  cursor: text;
  /* display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox; */
  display: inline-flex;
  /* -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center; */
  align-items: center;
  /* display: grid !important; */
  /* place-items: center !important; */
  width: 100% !important;
  /* height: auto !important; */
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: #495057 !important;
  transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
  background-clip: padding-box !important;
  border: 0.0625rem solid #d2d6da;
}

/* login btn class */
.login-btn {
  width: 93px;
  height: 39px;
  border-radius: 5px;
  border: 0px solid #fff;
  background-color: #60a7ff;
  color: "#fff";
  cursor: "pointer";
}

.login-btnNew {
  width: 93px;
  height: 39px;
  border-radius: 7px;
  border: 0px solid #fff;
  background-color: #fe5c00;
  color: "#fff";
  cursor: "pointer";
}

.login-btnNew1 {
  width: 93px;
  height: 39px;
  border-radius: 7px;
  border: 1px solid #0b0a0a;
  background-color: #fefefe;
  color: "#0b0a0a";
  cursor: "pointer";
}

.btn-txt {
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}
.btn-txt1 {
  color: #000000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}

.login-btn2 {
  width: 93px;
  height: 39px;
  border-radius: 5px;
  border: 0px solid #fff;
  background-color: #ffffff;
  color: "#fff";
  cursor: "pointer";
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(248, 248, 248);
  background-color: transparent;
  border-radius: 15px;
}

#style-1::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #9ca6c6;
  border: 2px solid #fffdfd;
  border-radius: 15px;
}

.btn-txt2 {
  color: #3b5092;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}

.login-btn3 {
  width: 80px;
  padding: 0px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  background-color: #0958d9;
}

.login-btn3-Text {
  font-size: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.login-btn4 {
  width: 80px;
  padding: 0px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  border: 1px solid grey;
  background-color: #ffffff;
}

.login-btn4:hover {
  width: 80px;
  padding: 2px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  border: 1px solid #0958d9;
  background-color: #ffffff;
  color: #0958d9;
}

.login-btn4-Text {
  font-size: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.login-btn4-Text:hover {
  color: #0958d9;
}

/* login btn class */

/* ******** */
.toggle-btn {
  border: "1px solid black";
  border-radius: 50%;
  width: 50px;
}

.bg-purple,
.badge-purple {
  background-color: #7460ee !important;
}

.bg-primary,
.badge-primary {
  background-color: #ff9b44 !important;
}

.bg-success,
.badge-success {
  background-color: #55ce63 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

.bg-danger,
.badge-danger {
  background-color: #f62d51 !important;
}

.bg-white {
  background-color: #fff;
}

.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #ff9b44 !important;
}

.text-success,
.dropdown-menu > li > a.text-success {
  color: #55ce63 !important;
}

.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #f62d51 !important;
}

.text-info,
.dropdown-menu > li > a.text-info {
  color: #009efb !important;
}

.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #8e8e8e !important;
}

.btn-purple {
  background-color: #7460ee;
  border: 1px solid #7460ee;
  color: #fff;
}

.btn-purple:hover,
.btn-purple:focus {
  background-color: #482fe9;
  border: 1px solid #482fe9;
}

/* ******** */

/* *** */
/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* height: 20px;
  width: 20px; */
  /* left: 10px;
  bottom: 4px; */
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 10px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
}
/* *** */

/* prod website class */
.css-zws9p5 {
  margin-top: 10px !important;
}
.css-18ua6wv {
  z-index: 0 !important;
}
/* prod website class */

/* sidenav css for demo link */
.css-e1df1o {
  z-index: 0 !important;
  position: fixed !important;
  /* top: 50px !important; */
}

/* sidenav css for demo link */
